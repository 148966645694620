<script>
import Layout from '../../layouts/main'
import appConfig from '@/app.config'
import api from '../../../helpers/axios'
import Subjob from './subjob'

/**
 * Invoice-detail component
 */
export default {
  page: {
    title: 'Bestanden aanleveren',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, Subjob },
  data() {
    return {
      title: 'Bestanden aanleveren.',
      job: {},
      hover: false,
      finishing: false,
    }
  },
  methods: {
    async getJob() {
      try {
        const job = await api().get(`/api/v1/jobs/${this.$route.params.id}`)
        if (job.data.data) {
          this.job = job.data.data
          return
        }
        this.job = false
        throw Error('Could not find job')
      } catch (error) {
        this.$toast.error('Wij hebben geen order kunnen vinden.', {
          position: 'top-right',
          duration: 7000,
        })

        this.job = {}
      }
    },
    async finishJob() {
      try {
        this.finishing = true
        const finished = await api().put(`/api/v1/jobs/${this.$route.params.id}/finish`)
        if (finished.status === 200) {
          this.$toast.success(`Voltooien gelukt!`, {
            position: 'top-right',
            duration: 7000,
          })
        }
        this.finishing = false
        this.getJob()
      } catch (error) {
        this.finishing = false
        this.$toast.error(`Voltooien niet gelukt: ${error.response.data.message}`, {
          position: 'top-right',
          duration: 7000,
        })
      }
    },
  },
  computed: {
    deliveryAddresses: function() {
      let deliveryAddresses = 0
      for (let i = 0; i < this.job.subJob.length; i += 1) {
        const subJob = this.job.subJob[i]
        deliveryAddresses = deliveryAddresses + subJob.deliveryAddress.length
      }
      return deliveryAddresses
    },
  },
  async mounted() {
    this.getJob()
  },
  middleware: 'router-auth',
}
</script>

<template>
  <Layout>
    <div class="row">
      <div class="overlay" v-if="finishing">
        <div class="loader"></div>
        <h1>Bestelling afronden, even geduld aub...</h1>
      </div>
      <div class="overlay" v-if="job.isAgreed && job.isApproved">
        <h1>Uw bestanden zijn aangeleverd.</h1>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="invoice-title">
              <h3 class="float-right font-size-20 pt-3">Order # {{ job.jobNumber }}</h3>
              <div class="mb-4">
                <img src="@/assets/images/easyprint-logo.png" alt="logo" height="50" />
              </div>
            </div>
            <hr />
            <div class="row" v-if="job">
              <div class="col-4 text-right">
                <address>
                  <strong class="font-size-18">{{ job.company }}</strong>
                  <br />{{ job.contact }} <br />{{ job.email }}
                </address>
                <address>
                  Orderdatum: {{ new Date(job.jobDate).toLocaleDateString('nl-NL') }}<br />
                  Leverdatum: {{ new Date(job.deliveryDate).toLocaleDateString('nl-NL') }}
                </address>
              </div>
              <div class="col-6">
                <div>
                  <p>Beste {{ job.contact }},</p>
                  <p>
                    Hartelijk dank voor uw bestelling. Hieronder uploadt u uw bestanden. Na het uploaden wordt uw bestand geverifieerd en gecontroleerd. Na deze
                    controle ziet u voorbeelden van wat u heeft geüploadet. Nadat u alle gewenste bestanden heeft aangeleverd, controleer dan de gerenderde
                    afbeeldingen van uw printwerk goed.
                  </p>
                  <p>Keurt u het resultaat goed? Klik u op de knop 'Voltooien', rechts onderin dit formulier.</p>
                </div>
              </div>
              <div class="col-3 mt-3 text-right"></div>
            </div>
            <div v-else class="row">
              <div class="col-3 mt-3 text-right"></div>
              <div class="col-6">
                <div>
                  <p>Geachte relatie,</p>
                  <p>Wij hebben uw ordernummer niet kunnen vinden. Gebruik de link die u in onze e-mail vindt.</p>
                  <p>Heeft u geen e-mail ontvangen, of wilt u deze opnieuw ontvangen, neem dan contact met ons op.</p>
                  <p>Met vriendelijke groet,</p>
                  <p>EasyPrint</p>
                </div>
              </div>
              <div class="col-3 mt-3 text-right"></div>
            </div>

            <div class="table-responsive" v-for="subjob in job.subJob" :key="subjob.id">
              <Subjob :subjob="subjob" :jobId="job.id" @changed="getJob" />
            </div>
            <div class="d-print-none" v-if="job.isApproved && deliveryAddresses > 0 && !job.isAgreed">
              <div class="float-right">
                <a href="javascript: void(0);" class="btn btn-success w-lg ml-1" style="font-size:18px;" @click="finishJob">Voltooien</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
<style scoped>
.loader {
  position: relative;
  border: 14px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #556ee6;
  width: 70px;
  height: 70px;
  left: 50%;
  top: 40%;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.6;
  z-index: 2000;
}
.overlay h1 {
  position: relative;
  width: 100%;
  text-align: center;
  color: #f3f3f3;
  top: 30%;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>
