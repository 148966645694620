<script>
import Attribute from './attribute.vue'
import Address from './delivery-addresses.vue'
import api from '../../../helpers/axios'

export default {
  components: {
    Attribute,
    Address,
  },
  data() {
    return {
      originalAdresses: this.subjob.deliveryAddress,
      subJob: this.subjob,
      totalCopies: 0,
    }
  },
  props: {
    subjob: Object,
    jobId: String,
  },
  methods: {
    async getSubjob() {
      try {
        this.previews = []
        this.totalCopies = 0
        const result = await api().get(`api/v1/jobs/${this.jobId}/subjob/${this.subjob.id}`)
        this.subJob = result.data.data
        this.$emit('changed', true)
      } catch (error) {
        console.error(error)
      }
    },
    setApproved() {
      this.$emit('changed', true)
    },
  },
  middleware: 'router-auth',
}
</script>

<template>
  <table class="table table-borderless">
    <tbody>
      <tr :key="subJob.id" style="font-size:15px; background-color:#009fee; color: #fff;">
        <td>
          <strong
            ><em>{{ subJob.name }}</em></strong
          >
        </td>
      </tr>
      <tr :key="subJob.id + Math.random()">
        <td colspan="3">
          <div class="table-response table-striped">
            <table class="table">
              <template v-for="attribute in subJob.attributes">
                <Attribute :attribute="attribute" :subjob="subJob" @changed="getSubjob" @approved="setApproved" :key="attribute._id" />
              </template>
            </table>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="4">
          <h5 class="font-size-14">Afleveradres(sen):</h5>
          <hr />
          <Address :subjob="subJob" :jobId="jobId" @changed="getSubjob" />
        </td>
      </tr>
    </tbody>
  </table>
</template>
<style>
.custom-file-label {
  background-color: rgb(255, 222, 130);
  font-weight: 600;
}
.custom-file-label:hover {
  cursor: pointer;
}
</style>
